import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import VideoCategoryNav from '../../../components/VideoCategoryNav';
import { useLocation } from '@reach/router';

const SonyCreCTwenty = ({ pageContext }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const [videoCount, setVideoCount] = useState(6);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const location = useLocation();

	const videos = [
		{
			id: 'uVwok64wF5E',
			url: 'https://www.youtube.com/embed/uVwok64wF5E?si=2NWCHFHp-wCh0kJP',
			title: 'How to download the Sony Hearing Control app on iPhone | hear.com',
			subtitle: 'We will guide you through the simple steps to download the Sony Hearing Control App on your iPhone.'
		},
		{
			id: 'A9tLfqAYZeA',
			url: 'https://www.youtube.com/embed/uVwok64wF5E?si=2NWCHFHp-wCh0kJP',
			title: 'How to download the Sony Hearing Control App on Android | hear.com',
			subtitle: 'We will guide you through the simple steps to download the Sony Hearing Control App on your Android smartphone.'
		},
		{
			id: 'OXFt9Ifx-rU',
			url: 'https://www.youtube.com/embed/OXFt9Ifx-rU?si=REtggCwsUXuaZ4da',
			title: 'How to create Sony account on iPhone | hear.com',
			subtitle: 'In this video, we will show you how to create a Sony account on iPhone to use the Sony Hearing Control App.'
		},
		{
			id: '5ZgPbuReU-I',
			url: 'https://www.youtube.com/embed/5ZgPbuReU-I?si=xmXsV2jL6OOKQzH0',
			title: 'How to create Sony account on Android | hear.com',
			subtitle: 'In this video, we will show you how to create a Sony account on Android to use the Sony Hearing Control App.'
		},
		{
			id: 'Hx_BGoQzQaw',
			url: 'https://www.youtube.com/embed/Hx_BGoQzQaw?si=dtJdxiYoKmEwzcaz',
			title: 'How to connect Sony CRE-C20 hearing aids to a smartphone | hear.com',
			subtitle: 'We will guide you through the step-by-step process of connecting your Sony CRE-C20 hearing aids to your smartphone.'
		},
		{
			id: '-ls9TXvxpfY',
			url: 'https://www.youtube.com/embed/-ls9TXvxpfY?si=PyMsv6UXqa6CN5QK',
			title: 'How to adjust Sony CRE-C20 hearing aids on a smartphone | hear.com',
			subtitle: 'How to easily adjust the settings of your Sony CRE-C20 hearing aids using your smartphone.'
		},
		{
			id: 'e1XjwSBpqIU',
			url: 'https://www.youtube.com/embed/e1XjwSBpqIU?si=-3XOkRoE9hSV9AJy',
			title: 'Unboxing Sony CRE-C20 hearing aids | hear.com',
			subtitle: 'Unboxing the highly anticipated Sony CRE-C20 hearing aids.'
		},
		{
			id: 'bPYgg4RWdiw',
			url: 'https://www.youtube.com/embed/bPYgg4RWdiw?si=nivaN5LJMGcPm03L',
			title: 'How to turn the Sony CRE-20 hearing aids on and off | hear.com',
			subtitle: 'Walkthrough on how to turn on and off your Sony CRE-C20 hearing aids.'
		},
		{
			id: 'ZSRmsfeMxw0',
			url: 'https://www.youtube.com/embed/ZSRmsfeMxw0?si=1CjqqHjhZUt--l-E',
			title: 'How to insert and remove Sony CRE-C20 hearing aids | hear.com',
			subtitle: 'Step-by-step guide on how to properly insert and remove your Sony CRE-C20 hearing aids.'
		},
		{
			id: 'pmO7wfFakHI',
			url: 'https://www.youtube.com/embed/pmO7wfFakHI?si=Gcqhvc5FP5lRHogQ',
			title: 'How to change and clean Sony CRE-C20 hearing aids sleeves | hear.com',
			subtitle: 'How to clean and change the sleeves on your Sony CRE-C20 hearing aids to ensure optimal performance.'
		},
		{
			id: 'Vlu5rQZDTwo',
			url: 'https://www.youtube.com/embed/Vlu5rQZDTwo?si=QcaLeHCQfXCDPehP',
			title: 'How to change Sony CRE-C20 hearing aids wax guards | hear.com',
			subtitle: 'How to change the wax guards on your Sony CRE-C20 hearing aids to ensure best performance.'
		},
		{
			id: 'P2p46BvoF6A',
			url: 'https://www.youtube.com/embed/P2p46BvoF6A?si=LW1ZKJRLczX_BP14',
			title: 'How to use Sony app with Sony CRE-C20 hearing aids | hear.com',
			subtitle: 'Get the most out of your Sony CRE-C20 hearing aids by using the Sony app.'
		}
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const videoId = params.get('videoID');
		if (videoId) {
			const video = videos.find((v) => v.id === videoId);
			setSelectedVideo(video);
		}
	}, [location]);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	const visibleVideos = videos.slice(0, videoCount);

	return (
		<Layout>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5"></div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<VideoCategoryNav />
				<div className="c-video-cards-wrapper o-row-3p5">
					{selectedVideo ? (
						<div className="c-video-container">
							<iframe
								className="c-video-card"
								title={selectedVideo.title}
								src={selectedVideo.url}
								allow="accelerometer; autoplay; picture-in-picture"
								allowFullScreen
							></iframe>
							<p className="c-video-title">{selectedVideo.title}</p>
							<p className="c-video-subtitle">{selectedVideo.subtitle}</p>
						</div>
					) : (
						visibleVideos.map((video, index) => (
							<div className="c-video-container" key={index}>
								<iframe
									className="c-video-card"
									title={video.title}
									src={video.url}
									loading={index < 6 ? 'eager' : 'lazy'}
									allow="accelerometer; autoplay; picture-in-picture"
									allowFullScreen
								></iframe>
								<p className="c-video-title">{video.title}</p>
								<p className="c-video-subtitle">{video.subtitle}</p>
							</div>
						))
					)}
				</div>
				{videos.length > videoCount && (
					<div className="c-show-more-wrapper">
						<button className="c-show-more-btn" onClick={handleShowMore}>
							Show More
						</button>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default SonyCreCTwenty;
